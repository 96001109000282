import React, { useRef, useEffect, useState } from 'react'
import Layout from "../share/layout";
import '../../styles/font-awesome-4.7.0/css/font-awesome.css'
import './global.css'
import './movil.css'
import Creditos from './credits/credit.jsx'
import Image1 from '../../images/sonidosaudiovisuales/face.png'
import Image2 from '../../images/sonidosaudiovisuales/logoblanco.png'
import Image3 from '../../images/sonidosaudiovisuales/page_emilio.jpg'
import Image4 from '../../images/sonidosaudiovisuales/page_jonathan.jpg'
import Image5 from '../../images/sonidosaudiovisuales/page_lucia.jpg'
import Image6 from '../../images/sonidosaudiovisuales/page_paola.jpg'
import Image7 from '../../images/sonidosaudiovisuales/page_sanz.jpg'
import Image8 from '../../images/sonidosaudiovisuales/page_vered.jpg'
import Image9 from '../../images/sonidosaudiovisuales/creditos.png'
import Image10 from '../../images/sonidosaudiovisuales/bglogo.png'


const SonidosAudiovisuales = () => {
    const it1Ref = useRef()
    const it2Ref = useRef()
    const it3Ref = useRef()
    const it4Ref = useRef()
    const it5Ref = useRef()
    const it6Ref = useRef()
    const rowRef = useRef()
    const innerRef = useRef()
    const [currentEl, setCurrentEl] = useState(false)
    const [mediaQuery, setMediaQuery] = useState(false)
    const [widthPage, setWidthPage] = useState(false)

    const change = (ref) => {
        const mediaQuery = window.matchMedia('(max-width: 1000px)')
        if (mediaQuery.matches) {
            if (ref === currentEl) {
                window.location.href = ref.current.getAttribute('href');
            }
            else {
                currentEl.current.classList.remove('mactive');
                setCurrentEl(ref)
                rowRef.current.style.left = `${innerRef.current.offsetWidth / 2 - it1Ref.current.offsetWidth / 2 - ref.current.positionLeft + it1Ref.current.offsetWidth * 0.2 / 2}px`
                setTimeout(function () {
                    rowRef.current.style.left = `${innerRef.current.offsetWidth / 2 - it1Ref.current.offsetWidth / 2 - ref.current.offsetLeft}px`
                }, 50);
            }
        }
        else {
            window.location.href = ref.current.getAttribute('href');
        }

    }
    const handleResize = () => {
        setWidthPage(window.innerWidth);
    }
    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    useEffect(
        () => {
            document.body.classList.add('body-sonidos-visuales');
            setMediaQuery(window.matchMedia('(max-width: 1000px)'))
            setCurrentEl(it1Ref)
            if (window.matchMedia('(max-width: 1000px)')) {
                rowRef.current.style.left = `${innerRef.current.offsetWidth / 2 - it1Ref.current.offsetWidth / 2 - it1Ref.current.positionLeft + it1Ref.current.offsetWidth * 0.2 / 2}px`
                setTimeout(function () {
                    rowRef.current.style.left = `${innerRef.current.offsetWidth / 2 - it1Ref.current.offsetWidth / 2 - it1Ref.current.offsetLeft}px`
                }, 50);
            }
            return function cleanup() {
                document.body.classList.remove('body-sonidos-visuales');
            };
        },
        [])
    useEffect(() => {
        if (mediaQuery) {
            if (!mediaQuery.matches) {
                rowRef.current.style.left = '0px'
                setTimeout(function () {
                    rowRef.current.style.left = '0px'
                }, 50);
            }
            else {
                rowRef.current.style.left = `${innerRef.current.offsetWidth / 2 - it1Ref.current.offsetWidth / 2 - currentEl.current.positionLeft + it1Ref.current.offsetWidth * 0.2 / 2}px`
                setTimeout(function () {
                    rowRef.current.style.left = `${innerRef.current.offsetWidth / 2 - it1Ref.current.offsetWidth / 2 - currentEl.current.offsetLeft}px`
                }, 50);
            }
        }
    }, [mediaQuery, widthPage])
    useEffect(() => {
        if (currentEl) {
            currentEl.current.classList.add('mactive');
        }
    },
        [currentEl])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
            />
            <meta property="og:site_name" content="Sonidos visuales" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Sonidos visuales" />
            <meta name="description" content="Sonidos visuales" />
            <meta name="abstract" content="Sonidos visuales" />
            <meta name="keywords" content="Sonidos visuales" />
            <meta name="news_keywords" content="Sonidos visuales" />
            <link rel="shortcut icon" href="../images/sonidosaudiovisuales/favicon.png" type="image/png" />
            <title>Sonidos visuales</title>
            <link rel="StyleSheet" href="css/global.css" type="text/css" />
            <link rel="StyleSheet" href="css/movil.css" type="text/css" />

            <div className="clearfix" id="page">

                <section id="section-content" className="section-content">

                    <div className="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img alt="" src={Image1} /></a></div>

                    <div id="principal" className="page page0 active" data-page="page0">
                        <span className="bg1"></span>
                        <span className="bg2"></span>
                        <span className="bglogo"></span>
                        <div className="block block-block block-logo" id="block-block-logo">
                            <div className="clearfix">
                                <div className="content clearfix">
                                    <img alt="" src={Image2} />
                                    <p>SEIS PROPUESTAS<br />AUDIOVISUALES</p>
                                </div>
                            </div>
                        </div>
                        <div ref={innerRef} className="inner">
                            <div className="cmovil">
                                <div className="bgmovil"></div>
                                <div ref={rowRef} className="row">
                                    <a ref={it1Ref} onClick={(e) => { change(it1Ref); e.preventDefault() }} href='/galeria/geografias/emilio' id="it1" className="item gopage" data-page="page1">
                                        <img alt="" src={Image3} />
                                    </a>
                                    <a ref={it2Ref} onClick={(e) => { change(it2Ref); e.preventDefault() }} href='/galeria/geografias/jonathan' id="it2" className="item gopage" data-page="page2" >
                                        <img alt="" src={Image4} />
                                    </a>
                                    <a ref={it3Ref} onClick={(e) => { change(it3Ref); e.preventDefault() }} href='/galeria/geografias/lucia' id="it3" className="item gopage" data-page="page3" >
                                        <img alt="" src={Image5} />
                                    </a>
                                    <a ref={it4Ref} onClick={(e) => { change(it4Ref); e.preventDefault() }} href='/galeria/geografias/paola' id="it4" className="item gopage sright" data-page="page4">
                                        <img alt="" src={Image6} />
                                    </a>
                                    <a ref={it5Ref} onClick={(e) => { change(it5Ref); e.preventDefault() }} href='/galeria/geografias/sanz' id="it5" className="item gopage sright" data-page="page5">
                                        <img alt="" src={Image7} />
                                    </a>
                                    <a ref={it6Ref} onClick={(e) => { change(it6Ref); e.preventDefault() }} href='/galeria/geografias/vered' id="it6" className="item gopage sright" data-page="page6">
                                        <img alt="" src={Image8} />
                                    </a>
                                </div>
                            </div>
                            <a className="creditos" href='/galeria/geografias/creditos'>
                                <img alt="" src={Image9} />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SonidosAudiovisuales